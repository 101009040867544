@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

/** Reset CSS **/
a {
  color: #fff;
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  text-decoration: none;
  color: #fff;
}
button {
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  outline: none;
}
input {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/** Color CSS **/
.point {
  background-color: #67e8f9;
}
.gray {
  background-color: #0f172a;
}

/** Custom CSS **/
.dashboard_container {
  background-color: #0f172a;
  font-family: Pretendard;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  color: white;
  height: 100vh;
}
.main {
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;
}
.main .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.total {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0 12px;
}
.total .total-title {
  font-size: 16px;
  line-height: 0;
}
.total .total-count {
  font-size: 20px;
  font-weight: 600;
  line-height: 0;
  color: #67e8f9;
}
.table {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  border-spacing: 0 1px;
}
.table th {
  padding: 20px;
  border-bottom: #0f172a 1px solid;
}
.table td {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: #0f172a 0.25px solid;
}
.table td:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2);
}
.table tr:last-child td {
  border-bottom: none;
}
.table .none {
  text-align: center;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
}
