@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

/** Reset CSS **/
a {
  color: #fff;
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  text-decoration: none;
  color: #fff;
}
button {
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  outline: none;
}
input {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/** Color CSS **/
.point {
  background-color: #06b6d4;
  color: white;
}
.gray {
  background-color: #0f172a;
}

/** Custom CSS **/
.home_container {
  background-color: #0f172a;
  font-family: Pretendard;
  height: 100vh;
  display: flex;
  align-items: center;
  color: white;
}

.form-address {
  max-width: 768px;
  min-width: 40vw;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.form-address input {
  padding: 8px 12px;
  flex-shrink: 1;
  width: 80%;
  border-radius: 4px;
  border: #a0b3b0 1px solid; /* gray-light */
  font-size: 20px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.form-address input:focus {
  padding: 8px 12px;
  flex-shrink: 1;
  width: 80%;
  border-radius: 4px;
  border: #06b6d4 2px solid; /* gray-light */
  font-size: 20px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.form-address button {
  padding: 8px 20px;
  border-radius: 4px;
  height: fit-content;
  font-size: 20px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
