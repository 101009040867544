@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

/** Reset CSS **/
a {
  color: #fff;
  text-decoration: none;
  outline: none;
}

a:hover,
a:active {
  text-decoration: none;
  color: #fff;
}

button {
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  outline: none;
}

input {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

p {
  margin: 0;
}

.nodes_container form {
  /* margin: 5rem auto 0; */
  width: 20rem;

  input {
    padding: 0 0.5rem;
    width: inherit;
    height: 2rem;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
  }

  .point {
    height: 2.5rem;
    font-size: 1rem;
    color: #fff;
    background: #0971f1;
    cursor: pointer;
  }
}

.form-address {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/** Color CSS **/
.point {
  background-color: #67e8f9;
}

.gray {
  background-color: #0f172a;
}

.text-error {
  color: #f43f5e;
}

.text-con {
  color: white; /*#60a5fa;*/
  display: flex;
  flex-wrap: wrap;
}

.text-stop {
  color: #fbbf24;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);

  border-radius: 9px;
}

.form_container form {
  display: flex;
  gap: 20px;
}

/** Custom CSS **/
.nodes_container {
  background-color: #0f172a;
  font-family: Pretendard;
  display: flex;
  align-items: center;
  color: white;
  overflow-y: scroll;
  padding: 100px 0;
}

.main {
  max-width: 75vw;
  margin: 0 auto;
}

.main .info {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.main .button_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.main .red_button {
  font-size: 10px;
  max-width: 30px;
  height: 25px;
}

.yellow_button {
  font-size: 10px;
  max-width: 30px;
  height: 25px;
}

.red_button:hover {
  background-color: #f43f5e;
}

.yellow_button:hover {
  background-color: #ffa447;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
}

.table-wrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  border-spacing: 0 1px;
}

.table th {
  padding: 20px;
  border-bottom: #0f172a 1px solid;
}

.table td {
  /*padding: 20px;*/
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: #0f172a 0.25px solid;
}

.table td:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2);
}

.table tr:last-child td {
  border-bottom: none;
}

.table .center {
  text-align: center;
}

.nodes {
  display: flex;
  align-items: center;
}

.nodes-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.nodes:not(:last-child) {
  margin-bottom: 10px;
}

.nodes .nodes-info svg {
  width: 16px;
}

.nodes .nodes-count {
  display: flex;
  align-items: center;
  gap: 2px;
}

.loader_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nodes-id {
  gap: 5px;
}
