.login_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  gap: 15px;
}

input {
  width: 250px;
  height: 30px;
  border-radius: 9px;
  font-family: "Helvetica";
  color: darkslategray;
  font-size: 20px;
  text-align: center;
}

label {
  font-family: "Helvetica";
  font-size: 20px;
  color: white;
}

button {
  font-family: "Helvetica";
  font-size: 20px;
  background-color: aqua;
  border-radius: 9px;
  padding: 5px;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}
